import { doc, setDoc, updateDoc, onSnapshot } from 'firebase/firestore';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { db } from '../../firebase-app';
import {
  authStateChangedAction,
  signOutAction,
} from '../../redux-store/auth-store';
import settings from '../../settings';
import {
  combineIvAndEncrypted,
  encrypt,
  exportKey,
  fromHexString,
  generateRandomKey,
  hmacSign,
  importHmacKey,
  importKey,
  makeFingerprint,
  splitIvAndEncrypted,
  toHexString,
  unwrapKey,
  wrapKey,
} from '../crypto-utils';
import { generateUuid } from '../utils';

export async function createProfile({
  userId,
  name,
  email,
  hmacKey,
  appKey,
  plainPassword,
}) {
  const fingerprint = await makeFingerprint(hmacKey, plainPassword);
  localStorage.setItem('fingerprint', toHexString(fingerprint));
  const randomBytes = window.crypto.getRandomValues(new Uint8Array(32));
  const userSecret = toHexString(randomBytes);
  const userAdditionalData = await hmacSign(
    hmacKey,
    new Uint8Array([...fingerprint, ...randomBytes]),
  );
  const userKey = await generateRandomKey();
  const { iv, wrapped } = await wrapKey(userKey, appKey, userAdditionalData);
  const wrappedKeyHex = combineIvAndEncrypted(iv, wrapped);

  const profile = {
    id: userId,
    name,
    email,
    userSecret,
    userKey: wrappedKeyHex,
  };
  await setDoc(doc(db, 'profiles', userId), profile);
  const encodedPassword = new TextEncoder('utf-8').encode(plainPassword).buffer;
  const { iv: one, encrypted: two } = await encrypt(
    appKey,
    encodedPassword,
    await hmacSign(
      hmacKey,
      new TextEncoder('utf-8').encode(`${userId}:${email}:${userSecret}`)
        .buffer,
    ),
  );
  const encryptedPassword = combineIvAndEncrypted(one, two);
  await setDoc(doc(db, 'mail', generateUuid()), {
    to: email,
    from: 'Lale Passwords <noreply.lale.io@gmail.com>',
    message: {
      subject: 'Welcome to Passwords!',
      html: `
      <p>This is your password recovery key.</p>
      <p><code>${encryptedPassword}</code></p>
      <p>Sincerly,</p>
      <p>Lale.io</p>
      `,
    },
  });
}

export async function updateProfile({ userId, name, email }) {
  // Add a new document in collection "cities"
  await updateDoc(doc(db, 'profiles', userId), {
    id: userId,
    name,
    email,
  });
}

export function useFirebaseProfile(userId, hmacKey, appKey) {
  const dispatch = useDispatch();
  useEffect(() => {
    async function doAsyncStuff(profile) {
      const fingerprintHex = localStorage.getItem('fingerprint');
      if (fingerprintHex) {
        const fingerprint = fromHexString(fingerprintHex);
        const userSecret = fromHexString(profile.userSecret);
        profile.userSecret = await hmacSign(
          hmacKey,
          new Uint8Array([...fingerprint, ...userSecret]),
        );
        const { iv, encrypted: wrapped } = splitIvAndEncrypted(profile.userKey);
        profile.userKey = await unwrapKey(
          iv,
          wrapped,
          appKey,
          profile.userSecret,
        );
        dispatch(authStateChangedAction(profile));
      } else {
        dispatch(signOutAction());
      }
    }
    if (userId && hmacKey && appKey) {
      const unsub = onSnapshot(doc(db, 'profiles', userId), (profileDoc) => {
        const profile = profileDoc.data();
        doAsyncStuff(profile);
      });
      return () => {
        unsub();
      };
    }
  }, [userId, dispatch, hmacKey, appKey]);
}
