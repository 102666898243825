import React, { useEffect, useRef, useState } from 'react';
import { isEmptyChildren, isFunction } from './react-utils';
import { Modal, Typography } from 'antd';
import {
  authStateChangedAction,
  signOutAction,
} from '../redux-store/auth-store';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
} from 'firebase/auth';
import { auth, db } from '../firebase-app';
import { createProfile, useFirebaseProfile } from './firebase-utils/profile';
import settings from '../settings';
import {
  convertPlainUserPassword,
  importHmacKey,
  importKey,
  makeFingerprint,
  toHexString,
} from './crypto-utils';

const { Text } = Typography;

const initialAuthState = {
  isLoading: true,
  isSignout: false,
  user: null,
};

export const AuthContext = React.createContext({
  signIn: async ({ email, plainPassword }) => undefined,
  signOut: () => undefined,
  signUp: async ({ name, email, plainPassword }) => undefined,
  state: initialAuthState,
});

export const AuthContextProvider = (props) => {
  const { component, children } = props;

  const dispatch = useDispatch();
  const authState = useSelector((store) => store.authState, shallowEqual);
  const currentUser = useSelector((store) => store.profile, shallowEqual);
  const state = React.useMemo(
    () => ({
      ...authState,
      user: currentUser,
    }),
    [authState, currentUser],
  );

  const [userId, setUserId] = useState();
  const hmacKey = useRef();
  const appKey = useRef();
  useFirebaseProfile(userId, hmacKey.current, appKey.current);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setUserId(user && user.uid);
      if (!user) {
        dispatch(authStateChangedAction(null));
      }
    });
  }, [dispatch]);

  useEffect(() => {
    async function doAsyncStuff() {
      hmacKey.current = await importHmacKey(settings.keySecret);
      appKey.current = await importKey(settings.appKey);
    }
    doAsyncStuff();
  }, []);

  const authContext = React.useMemo(
    () => ({
      signIn: async (values) => {
        const { email, plainPassword } = values;
        try {
          const password = await convertPlainUserPassword(
            settings.passwordSecret,
            plainPassword,
          );
          const fingerprint = await makeFingerprint(
            hmacKey.current,
            plainPassword,
          );
          localStorage.setItem('fingerprint', toHexString(fingerprint));
          const userCred = await signInWithEmailAndPassword(
            auth,
            email,
            password,
          );
          const user = userCred.user;
          setUserId(user.uid);
        } catch (err) {
          console.error(err.code, err.message);
          throw new Error(err.message);
        }
        return '';
      },
      signOut: async () => {
        signOut(auth);
        localStorage.setItem('fingerprint', null);
        dispatch(signOutAction());
      },
      signUp: async ({ name, email, plainPassword }) => {
        try {
          const password = await convertPlainUserPassword(
            settings.passwordSecret,
            plainPassword,
          );
          const userCredential = await createUserWithEmailAndPassword(
            auth,
            email,
            password,
          );
          const user = userCredential.user;
          await createProfile({
            userId: user.uid,
            name,
            email,
            hmacKey: hmacKey.current,
            appKey: appKey.current,
            plainPassword,
          });
        } catch (err) {
          console.error(err.code, err.message);
          throw new Error(err.message);
        }
      },
      state,
    }),
    [state, dispatch],
  );

  return (
    <AuthContext.Provider value={authContext}>
      {component
        ? React.createElement(component, authContext)
        : children // children come last, always called
        ? isFunction(children)
          ? children(authContext)
          : !isEmptyChildren(children)
          ? React.Children.only(children)
          : null
        : null}
    </AuthContext.Provider>
  );
};
