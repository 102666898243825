export const fromHexString = (hexString) =>
  new Uint8Array(hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16)));

export const toHexString = (bytes) =>
  bytes.reduce((str, byte) => str + byte.toString(16).padStart(2, '0'), '');

export function generateRandomBytes(n) {
  return window.crypto.getRandomValues(new Uint8Array(n));
}

export async function generateRandomKey() {
  try {
    const key = await window.crypto.subtle.generateKey(
      {
        name: 'AES-GCM',
        length: 256, //can be  128, 192, or 256
      },
      true, //whether the key is extractable (i.e. can be used in exportKey)
      ['encrypt', 'decrypt'], //can "encrypt", "decrypt", "wrapKey", or "unwrapKey"
    );
    return key;
  } catch (err) {
    console.error(err);
  }
}

export async function exportKey(key) {
  try {
    const jwt = await window.crypto.subtle.exportKey(
      'jwk', //can be "jwk" or "raw"
      key, //extractable must be true
    );
    return jwt;
  } catch (err) {
    console.error(err);
  }
}

export async function importKey(jwt) {
  try {
    const key = await window.crypto.subtle.importKey(
      'jwk', //can be "jwk" or "raw"
      jwt,
      {
        //this is the algorithm options
        name: 'AES-GCM',
      },
      true, //whether the key is extractable (i.e. can be used in exportKey)
      jwt.key_ops,
    );
    return key;
  } catch (err) {
    console.error(err);
  }
}

export async function encrypt(key, data, additionalData) {
  try {
    const iv = window.crypto.getRandomValues(new Uint8Array(12));
    const encrypted = await window.crypto.subtle.encrypt(
      {
        name: 'AES-GCM',
        //Don't re-use initialization vectors!
        //Always generate a new iv every time your encrypt!
        //Recommended to use 12 bytes length
        iv,
        //Additional authentication data (optional)
        additionalData,
        //Tag length (optional)
        tagLength: 128, //can be 32, 64, 96, 104, 112, 120 or 128 (default)
      },
      key, //from generateKey or importKey above
      data, //ArrayBuffer of data you want to encrypt
    );
    return { iv, encrypted: new Uint8Array(encrypted) };
  } catch (err) {
    console.error(err);
  }
}

export async function decrypt(iv, key, data, additionalData) {
  try {
    const decrypted = await window.crypto.subtle.decrypt(
      {
        name: 'AES-GCM',
        iv, //The initialization vector you used to encrypt
        additionalData, //The addtionalData you used to encrypt (if any)
        tagLength: 128, //The tagLength you used to encrypt (if any)
      },
      key, //from generateKey or importKey above
      data, //ArrayBuffer of the data
    );
    return new Uint8Array(decrypted);
  } catch (err) {
    console.error(err);
  }
}

export async function wrapKey(key, wrappingKey, additionalData) {
  try {
    const iv = window.crypto.getRandomValues(new Uint8Array(12));
    const wrapped = await window.crypto.subtle.wrapKey(
      'jwk', //can be "jwk", "raw", "spki", or "pkcs8"
      key, //the key you want to wrap, must be able to export to above format
      wrappingKey, //the AES-GCM key with "wrapKey" usage flag
      {
        //these are the wrapping key's algorithm options
        name: 'AES-GCM',
        //Don't re-use initialization vectors!
        //Always generate a new iv every time your encrypt!
        //Recommended to use 12 bytes length
        iv,
        //Additional authentication data (optional)
        additionalData,
        //Tag length (optional)
        tagLength: 128, //can be 32, 64, 96, 104, 112, 120 or 128 (default)
      },
    );
    return { iv, wrapped: new Uint8Array(wrapped) };
  } catch (err) {
    console.error(err);
  }
}

export async function unwrapKey(iv, wrapped, wrappingKey, additionalData) {
  try {
    const key = await window.crypto.subtle.unwrapKey(
      'jwk', //"jwk", "raw", "spki", or "pkcs8" (whatever was used in wrapping)
      wrapped, //the key you want to unwrap
      wrappingKey, //the AES-GCM key with "unwrapKey" usage flag
      {
        //these are the wrapping key's algorithm options
        name: 'AES-GCM',
        iv, //The initialization vector you used to encrypt
        additionalData, //The addtionalData you used to encrypt (if any)
        tagLength: 128, //The tagLength you used to encrypt (if any)
      },
      {
        //this what you want the wrapped key to become (same as when wrapping)
        name: 'AES-GCM',
        length: 256,
      },
      false, //whether the key is extractable (i.e. can be used in exportKey)
      ['encrypt', 'decrypt'], //the usages you want the unwrapped key to have
    );
    return key;
  } catch (err) {
    console.error(err);
  }
}

export function generateAppKey() {
  window.crypto.subtle
    .generateKey(
      {
        name: 'AES-GCM',
        length: 256, //can be  128, 192, or 256
      },
      true, //whether the key is extractable (i.e. can be used in exportKey)
      ['encrypt', 'decrypt', 'wrapKey', 'unwrapKey'], //can "encrypt", "decrypt", "wrapKey", or "unwrapKey"
    )
    .then((key) =>
      window.crypto.subtle
        .exportKey(
          'jwk', //can be "jwk" or "raw"
          key, //extractable must be true
        )
        .then((jwt) => console.log('AppKey', JSON.stringify(jwt))),
    );
}

export async function generateHmacKey() {
  try {
    const key = await window.crypto.subtle.generateKey(
      {
        name: 'HMAC',
        hash: { name: 'SHA-256' }, //can be "SHA-1", "SHA-256", "SHA-384", or "SHA-512"
        //length: 256, //optional, if you want your key length to differ from the hash function's block length
      },
      true, //whether the key is extractable (i.e. can be used in exportKey)
      ['sign', 'verify'], //can be any combination of "sign" and "verify"
    );
    return key;
  } catch (err) {
    console.error(err);
  }
}

export async function exportHmacKey(key) {
  try {
    const jwt = await window.crypto.subtle.exportKey(
      'jwk', //can be "jwk" or "raw"
      key, //extractable must be true
    );
    return jwt;
  } catch (err) {
    console.error(err);
  }
}

export async function importHmacKey(jwt) {
  try {
    const key = await window.crypto.subtle.importKey(
      'jwk', //can be "jwk" or "raw"
      jwt,
      {
        //this is the algorithm options
        name: 'HMAC',
        hash: { name: 'SHA-256' }, //can be "SHA-1", "SHA-256", "SHA-384", or "SHA-512"
        //length: 256, //optional, if you want your key length to differ from the hash function's block length
      },
      false, //whether the key is extractable (i.e. can be used in exportKey)
      ['sign', 'verify'], //can be any combination of "sign" and "verify"
    );
    return key;
  } catch (err) {
    console.error(err);
  }
}

export async function hmacSign(key, data) {
  try {
    const signature = await window.crypto.subtle.sign(
      {
        name: 'HMAC',
      },
      key, //from generateKey or importKey above
      data, //ArrayBuffer of data you want to sign
    );
    return new Uint8Array(signature);
  } catch (err) {
    console.error(err);
  }
}

export async function hmacVerify(key, data, signature) {
  try {
    const isvalid = await window.crypto.subtle.verify(
      {
        name: 'HMAC',
      },
      key, //from generateKey or importKey above
      signature, //ArrayBuffer of the signature
      data, //ArrayBuffer of the data
    );
    return isvalid;
  } catch (err) {
    console.error(err);
  }
}

export function generatePasswordSecretKey() {
  window.crypto.subtle
    .generateKey(
      {
        name: 'HMAC',
        hash: { name: 'SHA-256' }, //can be "SHA-1", "SHA-256", "SHA-384", or "SHA-512"
        //length: 256, //optional, if you want your key length to differ from the hash function's block length
      },
      true, //whether the key is extractable (i.e. can be used in exportKey)
      ['sign', 'verify'],
    )
    .then((key) =>
      window.crypto.subtle
        .exportKey(
          'jwk', //can be "jwk" or "raw"
          key, //extractable must be true
        )
        .then((jwt) => console.log('PasswordKey', JSON.stringify(jwt))),
    );
}

export function combineIvAndEncrypted(iv, encrypted) {
  return `${toHexString(iv)}${toHexString(encrypted)}`;
}

export function splitIvAndEncrypted(combined) {
  const one = combined.slice(0, 24);
  const two = combined.slice(24);
  return {
    iv: fromHexString(one),
    encrypted: fromHexString(two),
  };
}

export async function convertPlainUserPassword(secretKey, plainPassword) {
  const hmacKey = await importHmacKey(secretKey);
  const encodedPassword = new TextEncoder('utf-8').encode(plainPassword).buffer;
  const password = await hmacSign(hmacKey, encodedPassword);
  const passwordHex = toHexString(password);
  return passwordHex;
}

export async function makeFingerprint(hmacKey, plainPassword) {
  const fingerprint = await hmacSign(
    hmacKey,
    new TextEncoder('utf-8').encode(plainPassword),
  );
  return fingerprint;
}

export async function encryptPassword(userKey, userSecret, plainPassword) {
  const encodedPassword = new TextEncoder('utf-8').encode(plainPassword).buffer;
  const { iv: one, encrypted: two } = await encrypt(
    userKey,
    encodedPassword,
    userSecret,
  );
  const encryptedPassword = combineIvAndEncrypted(one, two);
  return encryptedPassword;
}

export async function decryptPassword(userKey, userSecret, encryptedPassword) {
  const { iv, encrypted } = splitIvAndEncrypted(encryptedPassword);
  const decrypted = await decrypt(iv, userKey, encrypted, userSecret);
  var plainPassword = new TextDecoder().decode(decrypted);
  return plainPassword;
}

function testWrapUnwrap() {
  let key;
  let wrappingKey;
  const additionalData = window.crypto.getRandomValues(new Uint8Array(32));
  window.crypto.subtle
    .generateKey(
      {
        name: 'AES-GCM',
        length: 256, //can be  128, 192, or 256
      },
      true, //whether the key is extractable (i.e. can be used in exportKey)
      ['encrypt', 'decrypt'], //can "encrypt", "decrypt", "wrapKey", or "unwrapKey"
    )
    .then((k) => {
      key = k;
      window.crypto.subtle
        .generateKey(
          {
            name: 'AES-GCM',
            length: 256, //can be  128, 192, or 256
          },
          true, //whether the key is extractable (i.e. can be used in exportKey)
          ['encrypt', 'decrypt', 'wrapKey', 'unwrapKey'], //can "encrypt", "decrypt", "wrapKey", or "unwrapKey"
        )
        .then((k2) => {
          wrappingKey = k2;
          const iv = window.crypto.getRandomValues(new Uint8Array(12));
          window.crypto.subtle
            .wrapKey(
              'jwk', //can be "jwk", "raw", "spki", or "pkcs8"
              key, //the key you want to wrap, must be able to export to above format
              wrappingKey, //the AES-GCM key with "wrapKey" usage flag
              {
                //these are the wrapping key's algorithm options
                name: 'AES-GCM',
                //Don't re-use initialization vectors!
                //Always generate a new iv every time your encrypt!
                //Recommended to use 12 bytes length
                iv,
                //Additional authentication data (optional)
                additionalData,
                //Tag length (optional)
                tagLength: 128, //can be 32, 64, 96, 104, 112, 120 or 128 (default)
              },
            )
            .then((w) => {
              console.log('w', w);
              window.crypto.subtle
                .unwrapKey(
                  'jwk', //"jwk", "raw", "spki", or "pkcs8" (whatever was used in wrapping)
                  w, //the key you want to unwrap
                  wrappingKey, //the AES-GCM key with "unwrapKey" usage flag
                  {
                    //these are the wrapping key's algorithm options
                    name: 'AES-GCM',
                    iv, //The initialization vector you used to encrypt
                    additionalData, //The addtionalData you used to encrypt (if any)
                    tagLength: 128, //The tagLength you used to encrypt (if any)
                  },
                  {
                    //this what you want the wrapped key to become (same as when wrapping)
                    name: 'AES-GCM',
                    length: 256,
                  },
                  false, //whether the key is extractable (i.e. can be used in exportKey)
                  ['encrypt', 'decrypt'], //the usages you want the unwrapped key to have
                )
                .then((uw) => {
                  console.log('uw', uw);
                });
            });
        });
    });
}
