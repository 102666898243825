export const READ_MY_PASSWORDS = 'READ_MY_PASSWORDS';
export const CREATE_MY_PASSWORD = 'CREATE_MY_PASSWORD';
export const UPDATE_MY_PASSWORD = 'UPDATE_MY_PASSWORD';
export const DELETE_MY_PASSWORD = 'DELETE_MY_PASSWORD';

// eslint-disable-next-line no-shadow
export const readMyPasswordsAction = (myPasswords) => ({
  type: READ_MY_PASSWORDS,
  payload: {
    myPasswords,
  },
});

export const createMyPasswordAction = (myPassword) => ({
  type: CREATE_MY_PASSWORD,
  payload: {
    myPassword,
  },
});

export const updateMyPasswordAction = (myPassword) => ({
  type: UPDATE_MY_PASSWORD,
  payload: {
    myPassword,
  },
});

export const deleteMyPasswordAction = (myPasswordId) => ({
  type: DELETE_MY_PASSWORD,
  payload: {
    myPasswordId,
  },
});

const initialMyPasswords = [];

export function myPasswords(state = initialMyPasswords, action) {
  switch (action.type) {
    case READ_MY_PASSWORDS: {
      const newState = { ...state };
      action.payload.myPasswords.forEach((p) => {
        newState[p.id] = p;
      });
      return newState;
    }
    case CREATE_MY_PASSWORD: {
      const current = state[action.payload.myPassword.id] || undefined;
      return {
        ...state,
        [action.payload.myPassword.id]: {
          ...current,
          ...action.payload.myPassword,
        },
      };
    }
    case UPDATE_MY_PASSWORD:
      return {
        ...state,
        [action.payload.myPassword.id]: action.payload.myPassword,
      };
    case DELETE_MY_PASSWORD: {
      const { myPasswordId } = action.payload;
      return { ...state, [myPasswordId]: undefined };
    }
    default:
      return state;
  }
}
