import { v4 as uuidv4 } from 'uuid';
import humanizeDuration from 'humanize-duration';
import stableStringify from 'fast-json-stable-stringify';
import short from 'short-uuid';

export function generateUuid() {
  return short.generate();
}

export const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const titleCase = (s) => {
  if (typeof s !== 'string') return '';
  return s
    .split(/\s/)
    .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
    .join(' ');
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const hashCode = (s) =>
  Array.from(s).reduce((prev, curr) => {
    const hash = (prev << 5) - prev + curr.charCodeAt(0);
    return hash & hash;
  }, 0);

export const shortEnglishHumanizer = humanizeDuration.humanizer({
  language: 'shortEn',
  languages: {
    shortEn: {
      y: () => 'y',
      mo: () => 'mo',
      w: () => 'w',
      d: () => 'd',
      h: () => 'h',
      m: () => 'm',
      s: () => 's',
      ms: () => 'ms',
    },
  },
});

export function compareObjects(a, b) {
  // console.log(
  //   stableStringify(a) === stableStringify(b),
  //   'a',
  //   stableStringify(a),
  //   'b',
  //   stableStringify(b),
  // );
  return stableStringify(a) === stableStringify(b);
}
