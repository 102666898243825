import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import {
  Card,
  Space,
  Button,
  Dropdown,
  Menu,
  Typography,
  Tag,
  message,
} from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { PasswordIcon } from '../../icons/Icons';
import settings from '../../settings';
import { decryptPassword } from '../../shared/crypto-utils';

const { Text, Title } = Typography;

function PasswordCard({
  profile,
  password,
  handleEdit,
  handleDelete,
  focusedPassword,
  handleClick,
  hasFocus,
}) {
  const [view, setView] = useState();
  const handleView = useCallback(async () => {
    if (view) {
      setView(undefined);
    } else {
      setView(
        await decryptPassword(
          profile.userKey,
          profile.userSecret,
          password.password,
        ),
      );
    }
  }, [password, view, profile]);

  const handleCopy = useCallback(async () => {
    if (navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(
          await decryptPassword(
            profile.userKey,
            profile.userSecret,
            password.password,
          ),
        );
        message.success('Password copied to clipboard!');
      } catch (err) {
        console.error(err);
      }
    }
  }, [profile, password]);

  const onMenuClick = useCallback(
    ({ key }) => {
      if (key === 'delete') {
        handleDelete(password);
      }
      if (key === 'edit') {
        handleEdit(password);
      }
      if (key === 'copy') {
        handleCopy();
      }
      if (key === 'view') {
        handleView();
      }
    },
    [handleDelete, handleEdit, password, handleView, handleCopy],
  );

  useEffect(() => {
    (async function copyPass() {
      if (hasFocus) {
        focusedPassword.current = await decryptPassword(
          profile.userKey,
          profile.userSecret,
          password.password,
        );
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFocus, password]);

  const windowWidth = useSelector(
    (store) => store.windowDimensions.width,
    shallowEqual,
  );

  const menu = (
    <Menu onClick={onMenuClick}>
      {windowWidth <= settings.screenSizes.md && (
        <>
          <Menu.Item key="view" icon={<EyeOutlined />}>
            View Password
          </Menu.Item>
          <Menu.Item key="copy" icon={<CopyOutlined />}>
            Copy Password
          </Menu.Item>
          <Menu.Divider />
        </>
      )}
      <Menu.Item key="edit" icon={<EditOutlined />}>
        Edit Password
      </Menu.Item>
      <Menu.Item key="delete" icon={<DeleteOutlined />}>
        Delete Password
      </Menu.Item>
    </Menu>
  );
  return (
    <Card
      style={{
        backgroundColor: hasFocus
          ? settings.colors.darkerOrange
          : settings.colors.darkOrange,
        border: `1px solid ${settings.colors.secondary}`,
      }}
      bodyStyle={{
        padding: windowWidth <= settings.screenSizes.md ? 16 : undefined,
      }}
    >
      <map onClick={handleClick} style={{ display: 'flex' }}>
        <div className="left">
          {view ? (
            <>
              <Title
                level={3}
                style={{
                  marginBottom: 0,
                  width:
                    windowWidth <= settings.screenSizes.md
                      ? windowWidth - 106
                      : undefined,
                }}
                ellipsis
              >
                {password.name}
              </Title>
              <Title
                level={3}
                style={{
                  marginTop: 0,
                  marginBottom: password.username && 4,
                  width:
                    windowWidth <= settings.screenSizes.md
                      ? windowWidth - 106
                      : undefined,
                }}
              >
                {view}
              </Title>
            </>
          ) : (
            <Title
              level={3}
              style={{
                marginBottom: password.username && 4,
                width:
                  windowWidth <= settings.screenSizes.md
                    ? windowWidth - 106
                    : undefined,
              }}
              ellipsis
            >
              {password.name}
            </Title>
          )}
          {password.username && (
            <div style={{ marginBottom: 12 }}>
              <Text level={3} style={{ color: settings.colors.secondary }}>
                {password.username}
              </Text>
            </div>
          )}
          {password.tags && (
            <div style={{ marginBottom: password.notes && 12 }}>
              {password.tags.map((tag) => (
                <Tag
                  key={tag}
                  color={settings.colors.secondary}
                  style={{ color: settings.colors.darkOrange }}
                >
                  {tag}
                </Tag>
              ))}
            </div>
          )}
          {password.notes && (
            <div>
              <Text level={3} style={{ color: settings.colors.secondary }}>
                {password.notes}
              </Text>
            </div>
          )}
        </div>
        <div style={{ flex: 1 }} />
        <div className="right">
          <Space>
            {windowWidth > settings.screenSizes.md && (
              <>
                <Button
                  style={{ color: settings.colors.secondary }}
                  type="text"
                  size="large"
                  onClick={handleCopy}
                  icon={<CopyOutlined />}
                />
                <Button
                  style={{ color: settings.colors.secondary }}
                  type="text"
                  size="large"
                  onClick={handleView}
                  icon={<EyeOutlined />}
                />
              </>
            )}
            <Dropdown overlay={menu} trigger={['click']}>
              <Button
                style={{ color: settings.colors.secondary }}
                icon={<MoreOutlined />}
                type="text"
                size="large"
              />
            </Dropdown>
          </Space>
        </div>
      </map>
    </Card>
  );
}

export default PasswordCard;
