// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyBtaEXVMZvk2J5uat-a1ifyT060Jo6XtOo',
  authDomain: 'passwords-a76e0.firebaseapp.com',
  projectId: 'passwords-a76e0',
  storageBucket: 'passwords-a76e0.appspot.com',
  messagingSenderId: '476973251022',
  appId: '1:476973251022:web:e836052cf81b26990cd39f',
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore();
export const auth = getAuth();
