const settings = {
  pageSize: 25,
  querySize: 100,
  cacheTimeout: 300000,
  apiUrl: process.env.REACT_APP_API_URL || 'http://localhost:8003/graphql/',
  apiWsUrl: process.env.REACT_APP_API_WS_URL || 'ws://localhost:8003/graphql/',
  pusherKey: process.env.REACT_PUSHER_KEY || '71369accacf9272ebe6c',
  pusherCluster: process.env.REACT_PUSHER_CLUSTER || 'us3',
  googleOAuthClientId:
    '949777386714-glaf5pg9i3htagj0vnallclvhe0e8uhs.apps.googleusercontent.com',
  googleOAuthRedirect: 'http://localhost:3000/home/',
  screenSizes: {
    lg: 992,
    md: 600,
    sm: 400,
  },
  passwordSecret: {
    alg: 'HS256',
    ext: true,
    k:
      '7Akwjymr_S_VPgUGVEZMSnVHYIMP7EQliqUYnLZYD2bpk-gOvCO_P3iwX74UGnqq4Kx0Fs7cuz9Qx67L0UVq0w',
    key_ops: ['sign', 'verify'],
    kty: 'oct',
  },
  keySecret: {
    alg: 'HS256',
    ext: true,
    k:
      '7Nexu5djeozGTEpOvx7A-5ze7DfH5EQLew6kopfLCrK2gvEcaVXiox7WpFvZjNLWopPQK_WsofX9Sf1QpZ-bmA',
    key_ops: ['sign', 'verify'],
    kty: 'oct',
  },
  appKey: {
    alg: 'A256GCM',
    ext: true,
    k: 'kuZ2UyTZNMjOtbyMZ8sVTuPaG6fdIqpy5cEEzCef6as',
    key_ops: ['encrypt', 'decrypt', 'wrapKey', 'unwrapKey'],
    kty: 'oct',
  },
  colors: {
    primary: '#E74E35',
    secondary: '#F6AE84',
    darkOrange: '#d94730',
    darkerOrange: '#c9402b',
    warning: '#E8BD4E',
    ghost: '#eef1f2',
    gray: '#E5E5E5',
    error: '#ff4d4f',
    red: '#f5222d',
    yellow: '#fadb14',
    green: '#73d13d',
    borderGray: '#d9d9d9',
    blue: '#495fa6',
    lightBlue: '#bbbfce',
  },
};

export default settings;
