import React, { useCallback, useState } from 'react';
import generator from 'generate-password-browser';
import {
  Button,
  Divider,
  Form,
  Input,
  InputNumber,
  Switch,
  Typography,
} from 'antd';
import settings from '../../settings';

const { Text } = Typography;

function PasswordGenerator({ onCancel, onFinish }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const handleFinish = useCallback(
    (values) => {
      setLoading(true);
      try {
        const password = generator.generate(values);
        onFinish(password);
        setLoading(false);
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
    },
    [onFinish],
  );
  return (
    <>
      <Divider style={{ color: settings.colors.primary }}>
        Generate Password
      </Divider>
      <Form
        form={form}
        onFinish={handleFinish}
        labelCol={{ span: 16 }}
        wrapperCol={{ span: 8 }}
        className="generate-form"
        size="small"
      >
        <Form.Item
          name="length"
          label="Password Length"
          initialValue={16}
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <InputNumber disabled={loading} min={0} />
        </Form.Item>
        <Form.Item
          name="numbers"
          label="Include Numbers"
          valuePropName="checked"
          initialValue={true}
        >
          <Switch disabled={loading} />
        </Form.Item>
        <Form.Item
          name="lowercase"
          label="Include Lowercase Letters"
          valuePropName="checked"
          initialValue={true}
        >
          <Switch disabled={loading} />
        </Form.Item>
        <Form.Item
          name="uppercase"
          label="Include Uppercase Letters"
          valuePropName="checked"
          initialValue={true}
        >
          <Switch disabled={loading} />
        </Form.Item>
        <Form.Item
          name="symbols"
          label="Include Symbols"
          valuePropName="checked"
          initialValue={false}
        >
          <Switch disabled={loading} />
        </Form.Item>
        <Form.Item
          name="excludeSimilarCharacters"
          label="Exclude similar characters like 'i' and 'l'"
          valuePropName="checked"
          initialValue={false}
        >
          <Switch disabled={loading} />
        </Form.Item>
        <Form.Item
          name="strict"
          label="Must include at least one from each of the above"
          valuePropName="checked"
          initialValue={true}
        >
          <Switch disabled={loading} />
        </Form.Item>
        <Form.Item
          name="exclude"
          label="Letters and/or Symbols to Exclude"
          initialValue=""
        >
          <Input disabled={loading} />
        </Form.Item>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            style={{ marginRight: 32, padding: '2px 16px', height: '32px' }}
            onClick={onCancel}
            disabled={loading}
          >
            Hide
          </Button>
          <Button
            htmlType="submit"
            type="primary"
            loading={loading}
            style={{ padding: '2px 16px', height: '32px' }}
          >
            Generate
          </Button>
        </div>
        {error && (
          <div>
            <Text type="danger">{error}</Text>
          </div>
        )}
      </Form>
      <Divider />
      <style jsx global>{`
        .generate-form .ant-form-item {
          flex-direction: row;
        }
        .generate-form .ant-form-item-label > label {
          height: unset;
        }
      `}</style>
    </>
  );
}

export default PasswordGenerator;
