import Fuse from 'fuse.js';
import { useCallback, useEffect, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { BehaviorSubject, of } from 'rxjs';
import autocomplete from '../../shared/autocomplete';
import { useFirebasePasswords } from '../../shared/firebase-utils/passwords';

const searchOptions = {
  includeScore: true,
  keys: ['name', 'username', 'tags', 'notes'],
};

export function usePasswords(userId, filters = {}) {
  const [refetches, setRefetches] = useState(0);
  const _refetches = useRef(-1);
  const term$ = useRef(null);
  const [search, setSearch] = useState();

  const { error, loading } = useFirebasePasswords(userId);

  const passwords = useSelector((store) => {
    let _passwords = Object.values(store.myPasswords)
      .filter((b) => b)
      .filter((b) => b.userId === userId);

    if (search) {
      const fuse = new Fuse(_passwords, searchOptions);
      _passwords = fuse
        .search(search)
        .sort((a, b) => a.score - b.score)
        .map((i) => i.item);
      return _passwords;
    }
    return _passwords.sort(
      (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt),
    );
  }, shallowEqual);

  useEffect(() => {
    term$.current = new BehaviorSubject('');
    term$.current
      .pipe(
        autocomplete(100, (term) => {
          return of(setSearch(term));
        }),
      )
      .subscribe();
  }, []);

  const passwordSearch = useCallback((term) => {
    if (term !== undefined) {
      term$.current.next(term);
    }
  }, []);

  return {
    passwords,
    loading,
    error,
    refetch: () => {
      setRefetches(refetches + 1);
    },
    passwordSearch,
  };
}
