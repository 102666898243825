import { ReloadOutlined, SearchOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Empty,
  Form,
  Input,
  List,
  message,
  Skeleton,
  Typography,
} from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import settings from '../../settings';
import { decryptPassword } from '../../shared/crypto-utils';
import useProfile from '../../shared/use-profile';
import PasswordCard from './PasswordCard';
import { usePasswords } from './use-passwords';

const { Text } = Typography;

function ListPasswords({ editing, handleEdit, handleDelete, searchRef }) {
  const [form] = Form.useForm();

  const profile = useProfile();
  const { error, loading, passwords, refetch, passwordSearch } = usePasswords(
    profile.id,
  );

  const focusedPassword = useRef();
  const [hasFocus, setHasFocus] = useState(0);
  const onSearchChange = useCallback(
    (changed) => {
      if (changed && changed.search !== undefined) {
        passwordSearch(changed.search);
        setHasFocus(0);
      }
    },
    [passwordSearch],
  );

  const clicking = useRef();
  const handleClick = useCallback((index) => {
    if (clicking.current) {
      if (navigator.clipboard && focusedPassword.current) {
        (async function doCopy() {
          try {
            await navigator.clipboard.writeText(focusedPassword.current);
            message.success('Password copied to clipboard!');
          } catch (err) {
            console.error(err);
          }
        })();
      }
    } else {
      setHasFocus(index);
      clearTimeout(clicking.current);
      clicking.current = () => {
        clicking.current = undefined;
      };
      setTimeout(clicking.current, 500);
    }
  }, []);

  useEffect(() => {
    const copyListener = (event) => {
      if (!editing && focusedPassword.current) {
        event.clipboardData.setData('text/plain', focusedPassword.current);
        event.preventDefault();
      }
    };
    document.addEventListener('copy', copyListener);
    return () => {
      document.removeEventListener('copy', copyListener);
    };
  }, [profile, editing]);

  return (
    <>
      <div className="top-actions">
        <Form
          layout="vertical"
          form={form}
          style={{ maxWidth: 500, minWidth: 300 }}
          onValuesChange={onSearchChange}
        >
          <Form.Item name="search">
            <Input
              placeholder="Search"
              suffix={<SearchOutlined />}
              ref={searchRef}
            />
          </Form.Item>
        </Form>
        {/* <Button icon={<ReloadOutlined />} onClick={refetch}>
          Refresh
        </Button> */}
      </div>
      {error && (
        <div className="errors">
          <Card>
            <Text type="danger">{error}</Text>
          </Card>
        </div>
      )}
      {loading && !passwords.length ? (
        <List
          itemLayout="vertical"
          grid={{ column: 1, sm: 1, lg: 1, gutter: 15 }}
          dataSource={[0, 0, 0, 0]}
          renderItem={(item) => (
            <List.Item>
              <Card bordered={false}>
                <Skeleton loading="true" avatar active />
              </Card>
            </List.Item>
          )}
        />
      ) : (
        <List
          className="passwordsList"
          dataSource={passwords}
          grid={{ column: 1, sm: 1, lg: 1, gutter: 20 }}
          rowKey="id"
          pagination={
            passwords.length > settings.pageSize
              ? {
                  defaultCurrent: 1,
                  pageSize: settings.pageSize,
                  total: passwords.length,
                }
              : false
          }
          renderItem={(password, index) => (
            <List.Item>
              <PasswordCard
                profile={profile}
                password={password}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                focusedPassword={focusedPassword}
                handleClick={() => handleClick(index)}
                hasFocus={index === hasFocus}
              />
            </List.Item>
          )}
          locale={{
            emptyText: (
              <List.Item>
                <Card
                  style={{
                    backgroundColor: settings.colors.darkOrange,
                    border: `1px solid ${settings.colors.secondary}`,
                  }}
                >
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No Passwords"
                    style={{ color: settings.colors.secondary }}
                  />
                </Card>
              </List.Item>
            ),
          }}
        />
      )}
      <style jsx>{`
        .body {
          display: flex;
        }
        .errors {
          text-align: center;
          padding: 16px;
        }
        .top-actions {
          display: flex;
          justify-content: center;
          width: 100%;
          flex-wrap: wrap;
        }
      `}</style>
    </>
  );
}

export default ListPasswords;
