import { ArrowLeftOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  Input,
  Modal,
  notification,
  Typography,
  Space,
  Select,
} from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  createMyPasswordAction,
  updateMyPasswordAction,
} from '../../redux-store/my-passwords-store';
import { decryptPassword, encryptPassword } from '../../shared/crypto-utils';
import {
  createPassword,
  updatePassword,
} from '../../shared/firebase-utils/passwords';
import useProfile from '../../shared/use-profile';
import PasswordGenerator from './PasswordGenerator';

const { Text, Title } = Typography;
const { TextArea } = Input;

function PasswordForm({
  profile,
  handleSave,
  loading,
  password,
  handleCancel,
}) {
  const [form] = Form.useForm();
  useEffect(() => {
    async function doAsyncStuff(pw) {
      const decrypted = await decryptPassword(
        profile.userKey,
        profile.userSecret,
        pw.password,
      );
      const decryptedNotes = pw.encryptedNotes
        ? await decryptPassword(
            profile.userKey,
            profile.userSecret,
            pw.encryptedNotes,
          )
        : undefined;
      form.setFieldsValue({
        ...password,
        password: decrypted,
        confirm: decrypted,
        encryptedNotes: decryptedNotes,
      });
    }
    if (password) {
      doAsyncStuff(password);
    }
  }, [password, form, profile]);
  const [showGenerate, setShowGenerate] = useState();
  const handleCancelGenerate = useCallback(() => {
    setShowGenerate(false);
  }, []);
  const handleGenerateFinish = useCallback(
    (newPass) => {
      form.setFieldsValue({
        password: newPass,
        confirm: newPass,
      });
    },
    [form],
  );
  return (
    <Form
      layout="vertical"
      onFinish={handleSave}
      form={form}
      style={{ maxWidth: 500 }}
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: 'Please enter a name' }]}
      >
        <Input disabled={loading} />
      </Form.Item>
      <Form.Item label="Username" name="username" rules={[]}>
        <Input disabled={loading} />
      </Form.Item>
      {showGenerate && (
        <PasswordGenerator
          onCancel={handleCancelGenerate}
          onFinish={handleGenerateFinish}
        />
      )}
      {!showGenerate && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button onClick={() => setShowGenerate(true)}>
            Generate Password
          </Button>
        </div>
      )}
      <Form.Item
        name="password"
        label="Password"
        rules={[
          {
            required: true,
            message: 'Please input a password',
          },
        ]}
        hasFeedback
      >
        <Input.Password disabled={loading} />
      </Form.Item>
      <Form.Item
        name="confirm"
        label="Confirm Password"
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Please confirm your password',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                'The two passwords that you entered do not match!',
              );
            },
          }),
        ]}
      >
        <Input.Password disabled={loading} />
      </Form.Item>
      <Form.Item label="Tags" name="tags">
        <Select
          mode="tags"
          style={{ width: '100%' }}
          placeholder="Type to create a tag"
        />
      </Form.Item>
      <Form.Item label="Notes" name="notes">
        <TextArea disabled={loading} />
      </Form.Item>
      <Form.Item label="Encrypted Notes" name="encryptedNotes">
        <TextArea disabled={loading} />
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldsError }) => {
          const errorList = getFieldsError();
          let showError = false;
          errorList.forEach((errors) => {
            if (errors.errors.length) {
              showError = true;
            }
          });
          return (
            showError && (
              <Text type="danger" style={{ marginTop: 16 }}>
                Please correct the errors above
              </Text>
            )
          );
        }}
      </Form.Item>
      <div style={{ height: 16 }} />
      <Form.Item>
        <Space
          style={{
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <Button
            key="cancel"
            onClick={handleCancel}
            htmlType="button"
            type="text"
            size="small"
            disabled={loading}
            icon={<ArrowLeftOutlined />}
            style={{ marginLeft: -7 }}
          >
            Cancel
          </Button>
          <Button key="send" type="primary" loading={loading} htmlType="submit">
            Save Password
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
}

const EditPasswordModal = ({ visible, onSuccess, onCancel }) => {
  const { passwordId, password, creating } = visible || {};
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const profile = useProfile();

  const handleSave = useCallback(
    async (_values) => {
      setLoading(true);
      const {
        confirm,
        name,
        username,
        password: pw,
        notes,
        encryptedNotes,
        tags,
      } = _values;
      try {
        if (creating) {
          const created = {
            id: passwordId,
            userId: profile.id,
            name: name,
            username: username || null,
            password: await encryptPassword(
              profile.userKey,
              profile.userSecret,
              pw,
            ),
            tags: tags || [],
            notes: notes || null,
            encryptedNotes: encryptedNotes
              ? await encryptPassword(
                  profile.userKey,
                  profile.userSecret,
                  encryptedNotes,
                )
              : null,
          };
          await createPassword(created);
          dispatch(createMyPasswordAction(created));
          notification.success({
            message: 'Saved',
            description: 'Password created successfully',
          });
        } else {
          const updated = {
            id: passwordId,
            userId: profile.id,
            name: name,
            username: username || null,
            password: await encryptPassword(
              profile.userKey,
              profile.userSecret,
              pw,
            ),
            tags: tags || [],
            notes: notes || null,
            encryptedNotes: encryptedNotes
              ? await encryptPassword(
                  profile.userKey,
                  profile.userSecret,
                  encryptedNotes,
                )
              : null,
          };
          await updatePassword(updated);
          dispatch(updateMyPasswordAction(updated));
          notification.success({
            message: 'Saved',
            description: 'Password updated successfully',
          });
        }
        onSuccess();
      } catch (err) {
        setError(err.message);
      }
      setLoading(false);
    },
    [dispatch, creating, onSuccess, profile, passwordId],
  );

  const title = useMemo(() => {
    if (creating) {
      return 'Create a Password';
    }
    return 'Edit Password';
  }, [creating]);

  return (
    <>
      <Modal
        header={null}
        visible={visible}
        closable={!loading}
        destroyOnClose={true}
        maskClosable={!loading}
        footer={null}
        onCancel={onCancel}
        bodyStyle={{
          paddingTop: 32,
        }}
      >
        <Title level={3} style={{ marginBottom: 4 }}>
          {title}
        </Title>
        {visible && (
          <PasswordForm
            profile={profile}
            handleSave={handleSave}
            loading={loading}
            password={password}
            handleCancel={onCancel}
          />
        )}
        {error && (
          <div className="errors">
            <Text type="danger">{error}</Text>
          </div>
        )}
      </Modal>
      <style jsx>{``}</style>
      <style jsx global>{``}</style>
    </>
  );
};

export default EditPasswordModal;
