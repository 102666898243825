import React from 'react';
import packageJson from '../../package.json';
import { Typography } from 'antd';
import settings from '../settings';

const { Text } = Typography;

const Version = () => {
  return (
    <div className="version">
      <Text
        style={{ color: settings.colors.secondary, fontSize: 16 }}
      >{`Version ${packageJson.version}`}</Text>
      <style jsx>{`
        .version {
          bottom: 16px;
          right: 16px;
        }
      `}</style>
    </div>
  );
};

export default Version;
