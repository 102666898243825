import { ArrowLeftOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  Input,
  Modal,
  notification,
  Typography,
  Space,
  Select,
} from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  createMyPasswordAction,
  deleteMyPasswordAction,
  updateMyPasswordAction,
} from '../../redux-store/my-passwords-store';
import settings from '../../settings';
import { deletePassword } from '../../shared/firebase-utils/passwords';
import useProfile from '../../shared/use-profile';

const { Text, Title } = Typography;
const { TextArea } = Input;

function PasswordForm({ handleDelete, loading, password, handleCancel }) {
  return (
    <>
      <div style={{ textAlign: 'center' }}>
        <Text>{`Are you sure you want to delete the password`}</Text>
        <div style={{ height: 12 }} />
        <Title
          level={3}
          style={{
            color: settings.colors.primary,
            marginTop: 0,
            marginBottom: 8,
          }}
        >
          {`${password.name}`}
        </Title>
      </div>
      <div style={{ height: 24 }} />
      <Form.Item>
        <Space
          size="large"
          style={{
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <Button
            key="cancel"
            onClick={handleCancel}
            type="text"
            size="small"
            disabled={loading}
            icon={<ArrowLeftOutlined />}
            style={{ marginLeft: -7 }}
          >
            Cancel
          </Button>
          <Button
            key="send"
            type="primary"
            loading={loading}
            onClick={handleDelete}
          >
            Delete Password
          </Button>
        </Space>
      </Form.Item>
    </>
  );
}

const DeletePasswordModal = ({ visible, onSuccess, onCancel }) => {
  const { password } = visible || {};
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const profile = useProfile();

  const handleDelete = useCallback(
    async (_values) => {
      setLoading(true);
      try {
        await deletePassword(password);
        dispatch(deleteMyPasswordAction(password));
        notification.success({
          message: 'Deleted',
          description: 'Password deleted successfully',
        });
        onSuccess();
      } catch (err) {
        setError(err.message);
      }
      setLoading(false);
    },
    [dispatch, password, onSuccess],
  );

  return (
    <>
      <Modal
        header={null}
        visible={visible}
        closable={!loading}
        destroyOnClose={true}
        maskClosable={!loading}
        footer={null}
        onCancel={onCancel}
        bodyStyle={{
          paddingTop: 40,
          paddingBottom: 12,
        }}
      >
        <Title level={3} style={{ marginBottom: 24, textAlign: 'center' }}>
          Delete Password
        </Title>
        {visible && (
          <PasswordForm
            handleDelete={handleDelete}
            loading={loading}
            password={password}
            handleCancel={onCancel}
          />
        )}
        {error && (
          <div className="errors">
            <Text type="danger">{error}</Text>
          </div>
        )}
      </Modal>
      <style jsx>{``}</style>
      <style jsx global>{``}</style>
    </>
  );
};

export default DeletePasswordModal;
