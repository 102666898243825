import { Button, Card, Form, Input, Space, Typography } from 'antd';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useGoogleLogin } from 'react-google-login';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import myLogo from '../assets/Passwords-logos_transparent.png';
import settings from '../settings';
import { AuthContext } from '../shared/auth-context';
import useProfile from '../shared/use-profile';

const { Title, Text } = Typography;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const SignIn = () => {
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState(null);
  const authContext = useContext(AuthContext);
  const history = useHistory();

  const onFinish = async (values) => {
    setLoading(true);
    setServerError(null);
    let error;
    try {
      await authContext.signIn(values);
    } catch (err) {
      setServerError(err.message);
      setLoading(false);
    }
  };

  const profile = useProfile();
  useEffect(() => {
    if (profile) {
      history.push('/home');
    }
  }, [profile, history]);

  return (
    <div className="page qr-gen">
      <div className="logo-box">
        <img className="logo" src={myLogo} alt="MG Logo" />
      </div>
      <Card>
        <Form
          layout="vertical"
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          className="signin-form"
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: 'Please enter your email' }]}
          >
            <Input disabled={loading} />
          </Form.Item>
          <Form.Item
            label="Password"
            name="plainPassword"
            rules={[{ required: true, message: 'Please enter your password' }]}
          >
            <Input.Password disabled={loading} />
          </Form.Item>
          <Form.Item>
            {/* <Button
              type="link"
              htmlType="button"
              onClick={() => history.push('/sign-up')}
            >
              Sign Up
            </Button> */}
            <Space style={{ width: '100%', justifyContent: 'center' }}>
              <Link to="/sign-up">
                <Button style={{ width: 150 }} disabled={loading}>
                  Create Account
                </Button>
              </Link>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: 150 }}
                loading={loading}
              >
                Sign In
              </Button>
            </Space>
          </Form.Item>
          {serverError && (
            <div
              className="server-error ant-form-item-has-error"
              style={{ marginTop: 16 }}
            >
              <div className="ant-form-item-explain">{serverError}</div>
            </div>
          )}
          {/* <Divider>Or</Divider>
          <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
            <Space
              align="center"
              style={{
                flexWrap: 'wrap',
                width: '100%',
                justifyContent: 'center',
              }}
            >
              <Button
                onClick={() => googleLogin.signIn()}
                icon={<GoogleOutlined />}
                style={{ marginBottom: 8 }}
                loading={googleLogin.loading}
              >
                Sign in with Google
              </Button>
              <Button
                onClick={() => authContext.signInWithApple()}
                icon={<AppleOutlined />}
                style={{ marginBottom: 8 }}
              >
                Sign in with Apple
              </Button>
            </Space>
          </Form.Item> */}
        </Form>
      </Card>
      <style jsx>{`
        .page {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          padding-top: 10vh;
          padding-bottom: 10vh;
        }
        .subtitle {
          font-size: 24px;
        }
        .server-error {
          text-align: center;
        }
        .sign-in-btns {
          display: flex;
          justify-content: center;
        }
        .logo {
          max-width: 400px;
          margin-left: 16px;
          margin-right: 16px;
        }
        .logo-sep {
          margin-left: 16px;
          margin-right: 16px;
          border-right: 1px solid #e8e8e8;
          height: 150px;
        }
        .logo-box {
          display: flex;
          flex-wrap: no-wrap;
          max-width: 100%;
          padding-left: 64px;
          padding-right: 64px;
          margin-left: -16px;
          margin-right: -16px;
          align-items: center;
          margin-bottom: 32px;
        }
        @media screen and (max-width: 992px) {
          .logo {
            max-width: unset;
            width: 35vw;
          }
          .logo-box {
            margin-top: 16px;
            margin-bottom: 16px;
            padding-left: 8px;
            padding-right: 8px;
          }
        }
        @media screen and (max-width: 600px) {
          .logo {
            max-width: unset;
            width: 35vw;
          }
          .logo-box {
            margin-top: 16px;
            margin-bottom: 16px;
            padding-left: 8px;
            padding-right: 8px;
          }
        }
      `}</style>
      <style jsx global>{`
        .qr-gen-title {
          text-align: center;
        }
        .ant-form-item-explain,
        .ant-form-item-extra {
          padding-bottom: 8px;
        }
        .signin-form {
          width: 30vw;
        }
        @media screen and (max-width: 992px) {
          .signin-form {
            width: 60vw;
          }
        }
        @media screen and (max-width: 600px) {
          .signin-form {
            width: 80vw;
          }
        }
      `}</style>
    </div>
  );
};

export default SignIn;
