import { createStore, combineReducers, applyMiddleware } from 'redux';
import enUS from 'antd/es/locale/en_US';
import { authState, profile, SIGN_OUT } from './auth-store';
import { myPasswords } from './my-passwords-store';
import { windowDimensions, responsiveMode } from './window-store';
import thunk from 'redux-thunk';

const appReducer = combineReducers({
  authState,
  profile,
  myPasswords,
  windowDimensions,
  responsiveMode,
  locale: () => enUS,
});

const rootReducer = (state, action) => {
  if (process.env.NODE_ENV === 'development') {
    console.log('state', state);
    console.log('action', action);
  }
  let newState;
  if (action.type === SIGN_OUT) {
    newState = appReducer(undefined, action);
    localStorage.clear();
  } else {
    newState = appReducer(state, action);
  }
  if (process.env.NODE_ENV === 'development') {
    console.log('new state', newState);
  }
  return newState;
};

export default createStore(rootReducer, applyMiddleware(thunk));
