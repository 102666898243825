import {
  doc,
  setDoc,
  updateDoc,
  deleteDoc,
  onSnapshot,
  collection,
  where,
  query,
} from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { db } from '../../firebase-app';
import {
  createMyPasswordAction,
  deleteMyPasswordAction,
  updateMyPasswordAction,
} from '../../redux-store/my-passwords-store';

const collectionName = 'passwords';
const subCollectionName = 'userPasswords';

export async function createPassword({
  id,
  userId,
  name,
  username,
  password,
  tags,
  notes,
  encryptedNotes,
}) {
  // Add a new document in collection "cities"
  await setDoc(
    doc(db, `${collectionName}/${userId}/${subCollectionName}`, id),
    {
      id,
      userId,
      name,
      username,
      password,
      tags,
      notes,
      encryptedNotes,
    },
  );
}

export async function updatePassword({
  id,
  userId,
  name,
  username,
  password,
  tags,
  notes,
  encryptedNotes,
}) {
  await updateDoc(
    doc(db, `${collectionName}/${userId}/${subCollectionName}`, id),
    {
      id,
      userId,
      name,
      username,
      password,
      tags,
      notes,
      encryptedNotes,
    },
  );
}

export async function deletePassword({ userId, id }) {
  await deleteDoc(
    doc(db, `${collectionName}/${userId}/${subCollectionName}`, id),
  );
}

export function useFirebasePasswords(userId) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (userId) {
      const q = query(
        collection(db, `${collectionName}/${userId}/${subCollectionName}`),
      );
      const unsubscribe = onSnapshot(
        q,
        (snapshot) => {
          snapshot.docChanges().forEach((change) => {
            if (change.type === 'added') {
              dispatch(createMyPasswordAction(change.doc.data()));
            }
            if (change.type === 'modified') {
              dispatch(updateMyPasswordAction(change.doc.data()));
            }
            if (change.type === 'removed') {
              dispatch(deleteMyPasswordAction(change.doc.data().id));
            }
          });
        },
        (err) => {
          console.error(err);
          setError(err.message);
        },
      );
      return () => {
        unsubscribe();
      };
    }
  }, [userId, dispatch]);

  return { loading, error };
}
