import { Layout, Typography } from 'antd';
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import AppLoading from './components/AppLoading';
import Footer from './components/Footer';
import Dashboard from './pages/Dashboard';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import settings from './settings';
import { AuthContextProvider } from './shared/auth-context';
import { useWindowDimensions } from './shared/window-dimensions';

const { Text } = Typography;

const App = () => {
  useWindowDimensions();
  const responsiveMode = useSelector(
    (store) => store.responsiveMode,
    shallowEqual,
  );
  return (
    <AuthContextProvider>
      {(context) => (
        <>
          {context.state.isLoading ? (
            <AppLoading />
          ) : (
            <Router>
              {context.state.user ? (
                <Layout
                  style={{
                    minHeight: '100vh',
                    background: settings.colors.primary,
                  }}
                >
                  <Switch>
                    <Route
                      exact
                      path="/"
                      render={() => <Redirect to="/home" />}
                    />
                    <Route
                      exact
                      path="/sign-(in|up)"
                      render={() => <Redirect to="/home" />}
                    />
                    <Route exact path="/home" component={Dashboard} />
                    <Route render={() => <Redirect to="/" />} />
                  </Switch>
                </Layout>
              ) : (
                <Layout
                  style={{
                    minHeight: '100vh',
                    background: settings.colors.primary,
                  }}
                >
                  <Switch>
                    <Route
                      exact
                      path="/"
                      render={() => <Redirect to="/sign-in" />}
                    />
                    <Route exact path="/sign-in" component={SignIn} />
                    <Route exact path="/sign-up" component={SignUp} />
                    <Route render={() => <Redirect to="/" />} />
                  </Switch>
                </Layout>
              )}
            </Router>
          )}
        </>
      )}
    </AuthContextProvider>
  );
};

export default App;
