import { Button, Dropdown, Menu, Space, Typography } from 'antd';
import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import {
  LockOutlined,
  LogoutOutlined,
  MoreOutlined,
  PlusOutlined,
  UserOutlined,
} from '@ant-design/icons';
import useProfile from '../shared/use-profile';
import logo from '../assets/logo-circle.png';
import settings from '../settings';
import Footer from '../components/Footer';
import { AuthContext } from '../shared/auth-context';
import { generateUuid } from '../shared/utils';
import EditPasswordModal from './passwords/EditPasswordModal';
import ListPasswords from './passwords/PasswordsList';
import DeletePasswordModal from './passwords/DeletePasswordModal';
import { shallowEqual, useSelector } from 'react-redux';

const { Title, Text } = Typography;

function Dashboard() {
  const history = useHistory();

  const profile = useProfile();

  const authContext = useContext(AuthContext);
  const doing = useRef(false);

  const [editingPassword, setEditingPassword] = useState();
  const onMenuClick = useCallback(
    ({ key }) => {
      if (key === 'password') {
        doing.current = true;
        setEditingPassword({
          passwordId: generateUuid(),
          creating: true,
        });
      }
      if (key === 'sign-out') {
        authContext.signOut();
      }
    },
    [authContext],
  );

  const onEditPasswordSuccess = useCallback(() => {
    setEditingPassword(undefined);
    doing.current = false;
  }, []);

  const onCancelEditPassword = useCallback(() => {
    setEditingPassword(undefined);
    doing.current = false;
  }, []);

  const [deletingPassword, setDeletingPassword] = useState();
  const onDeletePassword = useCallback((password) => {
    doing.current = true;
    setDeletingPassword({ password });
  }, []);

  const onCancelDeletePassword = useCallback((password) => {
    setDeletingPassword(undefined);
    doing.current = false;
  }, []);

  const onDeletePasswordSuccess = useCallback((password) => {
    setDeletingPassword(undefined);
    doing.current = false;
  }, []);

  const handleEdit = useCallback((password) => {
    doing.current = true;
    setEditingPassword({
      passwordId: password.id,
      password,
      creating: false,
    });
  }, []);

  const windowWidth = useSelector(
    (store) => store.windowDimensions.width,
    shallowEqual,
  );

  const searchRef = useRef();

  // useEffect(() => {
  //   const keyListener = (event) => {
  //     if (!doing.current) {
  //       if (event.key === 'f') {
  //         searchRef.current.focus();
  //       }
  //       if (event.key === 'a') {
  //         doing.current = true;
  //         setEditingPassword({
  //           passwordId: generateUuid(),
  //           creating: true,
  //         });
  //       }
  //     }
  //   };
  //   document.addEventListener('keyup', keyListener);
  //   return () => {
  //     document.removeEventListener('keyup', keyListener);
  //   };
  // }, []);

  const userMenu = (
    <Menu onClick={onMenuClick}>
      {windowWidth <= settings.screenSizes.md && (
        <>
          <Menu.Item key="profile" icon={<UserOutlined />}>
            {profile && profile.name}
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item key="password" icon={<LockOutlined />}>
            Create Password
          </Menu.Item>
          <Menu.Divider />
        </>
      )}
      <Menu.Item key="sign-out" icon={<LogoutOutlined />}>
        Sign Out
      </Menu.Item>
    </Menu>
  );

  const createMenu = (
    <Menu onClick={onMenuClick}>
      <Menu.Item key="password" icon={<LockOutlined />}>
        Create Password
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="page">
      <div className="nav-bar">
        <div className="logo-box">
          <img src={logo} className="logo" />
        </div>
        <div>
          <Title style={{ textAlign: 'center', margin: 0 }}>Passwords</Title>
        </div>
        <div style={{ flex: 1 }} />
        {windowWidth > settings.screenSizes.md && (
          <>
            <div style={{ marginRight: 16 }}>
              <Text style={{ color: settings.colors.secondary, fontSize: 16 }}>
                {profile && profile.name}
              </Text>
            </div>
            <Dropdown
              overlay={createMenu}
              trigger={['click']}
              style={{ marginRight: 16 }}
            >
              <Button
                style={{ color: settings.colors.secondary }}
                icon={<PlusOutlined />}
                type="text"
              />
            </Dropdown>
          </>
        )}
        <Dropdown overlay={userMenu} trigger={['click']}>
          <Button
            style={{ color: settings.colors.secondary }}
            icon={<MoreOutlined />}
            type="text"
          />
        </Dropdown>
      </div>
      <div className="body">
        <ListPasswords
          editing={!!editingPassword}
          handleEdit={handleEdit}
          handleDelete={(p) => onDeletePassword(p)}
          searchRef={searchRef}
        />
      </div>
      <Footer />
      <EditPasswordModal
        visible={editingPassword}
        onCancel={onCancelEditPassword}
        onSuccess={onEditPasswordSuccess}
      />
      <DeletePasswordModal
        visible={deletingPassword}
        onCancel={onCancelDeletePassword}
        onSuccess={onDeletePasswordSuccess}
      />
      <style jsx>{`
        .page {
          display: flex;
          min-height: 100vh;
          flex-direction: column;
        }
        .nav-bar {
          display: flex;
          align-items: center;
          height: 80px;
          margin-bottom: 32px;
          padding-left: 32px;
          padding-right: 32px;
        }
        .logo-box {
          margin-right: 16px;
        }
        .logo {
          height: 48px;
        }
        .body {
          flex: 1;
          padding-left: 10vw;
          padding-right: 10vw;
        }
        @media screen and (max-width: 600px) {
          .body {
            padding-left: 16px;
            padding-right: 16px;
          }
          .nav-bar {
            padding-left: 16px;
            padding-right: 16px;
            margin-bottom: 16px;
          }
        }
      `}</style>
      <style jsx global>{`
        body {
          background: ${settings.colors.primary};
        }
      `}</style>
    </div>
  );
}

export default Dashboard;
